import Vue from "vue";
import Vuex from "vuex";
import app from "./modules/app.js";
import calendario from "./modules/calendario.js";
import gare from "./modules/gare.js";
import messaggio from "./modules/messaggio.js";
import newsfeed from "./modules/newsfeed.js";
import acquisto from "./modules/acquisto.js";
import atleti from "./modules/atleti.js";
import utente from "./modules/utente.js";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
    modules: {
        app,
        calendario,
        gare,
        messaggio,
        newsfeed,
        acquisto,
        atleti,
        utente,
    },
    strict: debug,
    plugins: debug ? [] : [],
});
