import _calendario from "@src/js/vue/mixins/calendario.js";
import api from './api.js';
import { env } from './environment.js'
const endpoint = env.api_base_url+"/api/v2/lista_attivita.php";

const newsfeed = {
    addDays: function (ymd, days) {
        return _calendario.addDays(ymd, days);
    },
    sort: function (a, b) {
        return new Date(b.data) - new Date(a.data);
    },
    loadNewsFeeds: async function (from, to) {
        let url = endpoint+"?data_da="+from+"&data_a="+to;
        const result = await api(url, 'GET', null);
        return result;
    },
    fails: function () {
        return Promise.resolve({success: false});
    },
}
export default newsfeed;
