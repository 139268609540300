import axios from "axios"
import _storage from "./storage.js"
export const api = function (url, method, data = null, headers = null) {
    return new Promise((resolve)=>{
        if (!headers) {
            let auth_token = _storage.getAuthToken();
            if (auth_token) {
                headers = {
                    'Authorization': 'Bearer ' + auth_token
                }
            }
        }
        let config = {
            url: url,
            method: method,
            headers: headers,
        }
        if (data) {
            config.data = data;
        }
        axios(config)
            .then(
                (response)=>{
                    let result = response.data;
                    resolve(result);
                },
                ()=>{
                    resolve({
                        success: false,
                        data: null,
                    });
                }
            )
    });
};

export default  api;



export const URLparams = function (data) {
    // let params = Object.keys(data).map(function(k) {
    //   return encodeURIComponent(k) + "=" + encodeURIComponent(data[k]);
    // }).join('&');
    const URLparams = new URLSearchParams(Object.entries(data));
    return URLparams.toString();
}
