import api from "@mixins/api.js";
import _storage from "@mixins/storage.js"


// const getAtletaId =  function () {
//     const auth_token = localStorage.getItem('atletaId');
//     return auth_token;
// 
// };

const loadAllenatoreAtleta = async function() {
    return new Promise((resolve)=>{
        let api_base_url = process.env.VUE_APP_API_BASE_URL;
        let url = api_base_url+"/api/v2/allenatore_atleta.php";
        api(url, 'GET', null)
            .then((result)=>{
                if (result.success) {
                    resolve(result.data);
                } else {
                    resolve(null);
                }
            });
    });
};

const loadAtleti = async function(atletaIds) {
    return new Promise((resolve)=>{
        let api_base_url = process.env.VUE_APP_API_BASE_URL;
        let url = api_base_url+"/api/v2/atleta.php?ids="+atletaIds;
        api(url, 'GET', null)
            .then((result)=>{
                if (result.success) {
                    resolve(result.data);
                } else {
                    resolve(null);
                }
            });
    });
};

const loadAtleta = async function(atleta_id) {
    return new Promise((resolve)=>{
        let api_base_url = process.env.VUE_APP_API_BASE_URL;
        let url = api_base_url+"/api/v2/atleta.php?id="+atleta_id;
        api(url, 'GET', null)
            .then((result)=>{
                if (result.success) {
                    resolve(result.data);
                } else {
                    resolve(null);
                }
            });
    });
};


const state = () => ({
    atleta: null,
    atleti: [],
    atletaId: 0,
});

// getters
const getters = {
    atleti: (state) => { return state.atleti; },
    atleta: (state) => { return state.atleta; },
    atletaId: (state) => { return state.atletaId; },

};

// actions: (dispatch) sono il Controller 
const actions = {

    loadAtleti: async function ({state, commit}) {
        if (state.atleti && state.atleti.length) {
            return;
        }
        let allenatoreAtleta = await loadAllenatoreAtleta();
        let atleti;
        if (!allenatoreAtleta) {
            atleti = [];
        } else {
            const atletaIds = allenatoreAtleta.map(el=>el.atleta_id).join(",");
            atleti = await loadAtleti(atletaIds);
        }
        commit('setAtleti', atleti);
    },

    loadAtleta: async function ({commit}, atleta_id) {
        if (!atleta_id) return;
        //if (state.atleta && state.atleta.id == atleta_id) return;
        let atleta = await loadAtleta(atleta_id);
        if (atleta) {
            commit('setAtleta', atleta);
        } else {
            commit('setAtleta', null);
        }
    },

    setAtleta: function ({commit}, atleta) {
        commit('setAtleta', atleta);
    },

};

// mutations: (commit) cambiano lo state (Model)
const mutations = {
    setAtleti (state, atleti) {
        state.atleti = atleti;
    },

    setAtleta (state, atleta) {
        state.atleta = atleta;
        const atletaId = atleta ? atleta.id : null;
        state.atletaId = atletaId;
        _storage.setAtletaId(atletaId);
    },

}

  export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
  };
