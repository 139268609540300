import Vue from "vue"
import store from "../store";
import VueRouter from "vue-router"
import _api from "@mixins/api.js";
import Cookies from 'js-cookie'
import mapRoutes from "./mapRoutes.js"

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter)
function paramsNewItem (route) {
    return {
        id: parseInt(route.params.id),
        calendar: true,
    }
}

function isCoach() {
    let utente = store.getters['utente/utente'];
    if (!utente) return false;
    return (!utente.is_atleta);
}

const routes = [
    {
        path: "/",
        name: "home",
        component: () => import(/* webpackChunkName: "login" */ "@pages/login/Login.vue"),
    },
    {
        path: "/otp/confirm",
        name: "otp_confirm",
        component: () => import(/* webpackChunkName: "login" */ "@pages/login/OtpConfirm.vue"),
    },
    {
        path: "/otp/request",
        name: "otp_request",
        component: () => import(/* webpackChunkName: "login" */ "@pages/login/OtpRequest.vue"),
    },
    {
        path: "/otp/login",
        name: "otp_login",
        component: () => import(/* webpackChunkName: "login" */ "@pages/login/OtpLogin.vue"),
    },
    {
        path: "/feedback",
        name: "feedback",
        component: () => import(/* webpackChunkName: "feedback" */ "@pages/calendario/Feedback.vue"),
    },
    {
        path: "/coach",
        name: "coach",
        component: () => import(/* webpackChunkName: "coach" */ "@pages/utente/Coach.vue"),
    },
    {
        path: "/chats",
        name: "chats",
        component: () => import(/* webpackChunkName: "messaggi" */ "@pages/messaggi/Chats.vue"),
    },
    {
        path: "/commenti",
        name: "commenti",
        component: () => import(/* webpackChunkName: "messaggi" */ "@pages/messaggi/Commenti.vue"),
    },
    {
        path: "/chats/:id",
        name: "messaggi",
        component: () => import(/* webpackChunkName: "messaggi" */ "@pages/messaggi/Messaggi.vue"),
        meta: {
            showModal: true
        },
    },
    {
        path: "/acquisti/sospesi",
        name: "acquisti_sospesi",
        component: () => import(/* webpackChunkName: "acquisti" */ "@pages/shop/AcquistiSospesi.vue"),
        children: [
            {
                path: "form/:id",
                name: "acquisti_sospesi_form",
                component: () => import(/* webpackChunkName: "acquisti" */ "@pages/shop/FormAcquisto.vue"),
                meta: {
                    showModal: true
                },
                props: true, 
            },
            {
                path: "alert",
                name: "acquisti_sospesi_alert",
                component: () => import(/* webpackChunkName: "acquisti" */ "@pages/shop/AlertAcquisto.vue"),
                meta: {
                    showModal: true
                },
                props: true, 
            },
        ]
    },
    {
        path: "/register",
        name: "register",
        component: () => import(/* webpackChunkName: "register" */ "@pages/login/Register.vue"),
    },
    {
        path: "/account",
        name: "account",
        component: () => import(/* webpackChunkName: "account" */ "@pages/utente/Account.vue"),
    },
    {
        path: "/account/delete",
        name: "delete",
        component: () => import(/* webpackChunkName: "account" */ "@pages/utente/DeleteAccount.vue"),
    },
    {
        path: "/account/profilo",
        name: "profilo",
        component: () => import(/* webpackChunkName: "account" */ "@pages/utente/Profilo.vue"),
    },
    {
        path: "/account/settimana-tipo",
        name: "settimana-tipo",
        component: () => import(/* webpackChunkName: "account" */ "@pages/utente/SettimanaTipo.vue"),
    },
    {
        path: "/account/consensi",
        name: "consensi",
        component: () => import(/* webpackChunkName: "account" */ "@pages/utente/Consensi.vue"),
    },
    {
        path: "/account/connessioni",
        name: "connessioni",
        component: () => import(/* webpackChunkName: "account" */ "@pages/utente/Connessioni.vue"),
    },
    {
        path: "/account/acquisti",
        name: "account_acquisti",
        component: () => import(/* webpackChunkName: "account" */ "@pages/shop/Acquisti.vue"),
        children: [
            {
                path: ":id",
                name: "acquisti_dettaglio",
                component: () => import(/* webpackChunkName: "account" */ "@pages/shop/DettaglioAcquisto.vue"),
                props: true, 
            },
        ]
    },
    {
        path: "/shop",
        name: "shop",
        component: () => import(/* webpackChunkName: "shop" */ "@pages/shop/Shop.vue"),
    },
    {
        path: "/checkout",
        name: "checkout",
        component: () => import(/* webpackChunkName: "shop" */ "@pages/shop/Checkout.vue"),
    },
    {
        path: "/report",
        name: "report",
        component: () => import(/* webpackChunkName: "report" */ "@pages/report/Report.vue"),
        meta: {
            showModal: true
        },
    },
    {
        path: "/record",
        name: "record",
        component: () => import(/* webpackChunkName: "record" */ "@pages/record/RecordSegmenti.vue"),
    },
    {
        path: "/record/analisi",
        name: "record_analisi",
        component: () => import(/* webpackChunkName: "record" */ "@pages/record/AnalisiRecord.vue"),
    },
    {
        path: "/gare",
        name: "gare",
        component: () => import(/* webpackChunkName: "gare" */ "@pages/gare/Gare.vue"),
        children: [
            {
                path: "gara/view/:id",
                name: "gare_gara_view",
                component: () => import(/* webpackChunkName: "dettaglio" */ "@pages/gare/MspGaraDetail.vue"),
                meta: {
                    showModal: true
                },
            },
            {
                path: "gara/add/:id",
                name: "gare_gara_add",
                component: () => import(/* webpackChunkName: "dettaglio" */ "@pages/gare/MspEditGara.vue"),
                meta: {
                    showModal: true
                },
                props: paramsNewItem,
            },
            {
                path: "gara/edit/:id",
                name: "gare_gara_edit",
                component: () => import(/* webpackChunkName: "dettaglio" */ "@pages/gare/MspEditGara.vue"),
                meta: {
                    showModal: true
                },
                props: true
            },
        ],
    },
    {
        path: "/test",
        name: "test",
        component: () => import(/* webpackChunkName: "test" */ "@pages/utente/MspTestAttivi.vue"),
    },
    {
        path: "/atleti",
        name: "atleti",
        component: () => import(/* webpackChunkName: "atleti" */ "@pages/utente/Atleti.vue"),
        meta: {
            showModal: true
        },
        props: paramsNewItem, 
    },
    {
        path: "/calendario",
        name: "calendario",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "calendario" */ "@pages/calendario/Calendario.vue"),
        // ADDED
        children: [
            {
                path: "test/edit/:id",
                name: "test_edit",
                component: () => import(/* webpackChunkName: "dettaglio" */ "@pages/calendario/MspAddTest.vue"),
                meta: {
                    showModal: true
                },
                props: paramsNewItem, 
            },
            {
                path: "test/add/",
                name: "test_add",
                component: () => import(/* webpackChunkName: "dettaglio" */ "@pages/calendario/MspAddTest.vue"),
                meta: {
                    showModal: true
                },
                props: paramsNewItem, 
            },
            {
                path: "misurazione/add",
                name: "misurazione",
                component: () => import(/* webpackChunkName: "misurazione" */ "@pages/calendario/AggiungiMisurazione.vue"),
                meta: {
                    showModal: true
                },
                props: paramsNewItem, 
            },
            {
                path: "attivita/view/:id",
                name: "attivita_view",
                component: () => import(/* webpackChunkName: "dettaglio" */ "@pages/calendario/MspAttivitaDetail.vue"),
                meta: {
                    showModal: true
                },
                props: paramsNewItem, 
            },
            {
                path: "risultato/view/:id",
                name: "risultato_view",
                component: () => import(/* webpackChunkName: "dettaglio" */ "@pages/calendario/MspRisultatoDetail.vue"),
                meta: {
                    showModal: true
                },
                props: paramsNewItem, 
            },
            {
                path: "allenamento/view/:id",
                name: "allenamento_view",
                component: () => import(/* webpackChunkName: "dettaglio" */ "@pages/calendario/MspAllenamentoDetail.vue"),
                meta: {
                    showModal: true
                },
                props: paramsNewItem, 
            },
            {
                path: "allenamento/add/:id",
                name: "allenamento_add",
                component: () => import(/* webpackChunkName: "dettaglio" */ "@pages/calendario/MspEditAllenamento.vue"),
                meta: {
                    showModal: true
                },
                props: paramsNewItem, 
            },
            {
                path: "allenamento/edit/:id",
                name: "allenamento_edit",
                component: () => import(/* webpackChunkName: "dettaglio" */ "@pages/calendario/MspEditAllenamento.vue"),
                meta: {
                    showModal: true
                },
                props: true
            },
            {
                path: "gara/view/:id",
                name: "gara_view",
                component: () => import(/* webpackChunkName: "dettaglio" */ "@pages/gare/MspGaraDetail.vue"),
                meta: {
                    showModal: true
                },
            },
            {
                path: "gara/add/:id",
                name: "gara_add",
                component: () => import(/* webpackChunkName: "dettaglio" */ "@pages/gare/MspEditGara.vue"),
                meta: {
                    showModal: true
                },
                props: paramsNewItem,
            },
            {
                path: "gara/edit/:id",
                name: "gara_edit",
                component: () => import(/* webpackChunkName: "dettaglio" */ "@pages/gare/MspEditGara.vue"),
                meta: {
                    showModal: true
                },
                props: true
            },
            {
                path: "gara",
                name: "gara",
                component: () => import(/* webpackChunkName: "dettaglio" */ "@pages/gare/MspGaraDetail.vue"),
                meta: {
                    showModal: true
                },

            },
            {
                path: "impegno/view/:id",
                name: "impegno_view",
                component: () => import(/* webpackChunkName: "dettaglio" */ "@pages/calendario/MspImpegnoDetail.vue"),
                meta: {
                    showModal: true
                },
            },
            {
                path: "impegno/edit/:id",
                name: "impegno_edit",
                component: () => import(/* webpackChunkName: "dettaglio" */ "@pages/calendario/MspEditImpegno.vue"),
                meta: {
                    showModal: true
                },
                props: true
            },
            {
                path: "impegno/add/:id",
                name: "impegno_add",
                component: () => import(/* webpackChunkName: "dettaglio" */ "@pages/calendario/MspEditImpegno.vue"),
                meta: {
                    showModal: true
                },
                props: paramsNewItem, 
            },
            {
                path: "impegno",
                name: "impegno",
                component: () => import(/* webpackChunkName: "dettaglio" */ "@pages/calendario/MspImpegnoDetail.vue"),
                meta: {
                    showModal: true
                },

            },
            {
                path: "test/add/:date/:id",
                name: "test_add_date_id",
                component: () => import(/* webpackChunkName: "dettaglio" */ "@pages/calendario/MspAddTest.vue"),
                meta: {
                    showModal: true
                },

                props: true
            },
            {
                path: "test/:id",
                name: "test_id",
                component: () => import(/* webpackChunkName: "dettaglio" */ "@pages/calendario/MspTestDetail.vue"),
                meta: {
                    showModal: true
                },

                props: true
            },

        ]
    },
    {
        path: "*",
        component: () => import(/* webpackChunkName: "login" */ "@pages/login/Login.vue"),
    },


];

const router = new VueRouter({
  scrollBehavior() {
    return { x: 0, y: 0 };
  },

  mode: "history",
  base: process.env.BASE_URL,
  routes,
});


async function checkLogin () {
    const loggedIn = await store.dispatch('utente/login');
    return loggedIn;
}

async function getLicence () {
    return new Promise((resolve) => {
        const url = process.env.VUE_APP_API_BASE_URL+"/api/v2/ecommerce.php?check_licenza";
        _api(url,"GET")
            .then((result)=>{
                if (result.success) {
                    resolve(true);
                } else {
                    resolve(false);
                }
            })
            .catch(()=>{
                resolve(false);
            });
    });
}

router.beforeEach(async (to, from, next) => {
    const whitelist = [
        'home',
        'register',
        'otp_request',
        'otp_login',
        'otp_confirm',
        'login',
    ];

    let route;
    let path = to.fullPath;
    if (path in mapRoutes) {
        const name = mapRoutes[path];
        route = {
            name: name,
        }
    } else if (to.query.ref) {
        let ref = to.query.ref;
        let a = ref.split("?");
        if (a[0] in mapRoutes) {
            const name = mapRoutes[a[0]];
            const sp = new URLSearchParams(a[1]);
            let query = {};
            sp.forEach((value,key)=>{
                query[key] = value;
            });
            route = {
                name: name,
                query: query,
            }
        }


        let url = decodeURIComponent(to.query.ref);
        let t = 0;
        url = url.replace(/\?/g, match => ++t === 2 ? '&' : match)
        let parsedUrl = new URL(url);
        let coupon = parsedUrl.searchParams.get("coupon");
        let cp_c_key = parsedUrl.searchParams.get("CP_C_KEY");

        if (coupon) {
            Cookies.set('CP_COUPON', coupon);
        }

        if (cp_c_key) {
            Cookies.set('CP_C_KEY', cp_c_key);
        }
    }


    if (route) {
        next(route);
    } else {

        const loggedIn = await checkLogin();
        const userIsCoach = isCoach();
        if (whitelist.indexOf(to.name) !== -1) {
            next();
        } else if (!loggedIn) {
            let query = {
                path: to.path,
                query: to.query,
            }
            next({ name: 'home', query: query});
        } else if (to.name === 'shop') {
            if (userIsCoach) {
                next({ name: 'coach' });
            } else {
                next();
            }
        } else if (to.name !== 'checkout' && Cookies.get('CP_COUPON')) {
            next({ name: 'checkout'})
        } else if (to.name === 'calendario') {
            if (userIsCoach) {
                next(); 
                return;
            } else { 
                let hasLicence = await getLicence();
                if (!hasLicence) {
                    next({ name: 'shop' });
                } else {
                    next();
                }
            }
        } else {
            next();
        }
    }
})

export default router;
