import _api from "@mixins/api.js";
import { URLparams } from "@mixins/api.js";
import _cal from "@src/js/vue/mixins/calendario.js";
import _misurazioni from "@mixins/misurazioni.js"

const eventiConCommenti = [
    'allenamento',
    'gara',
];

const countCommenti = function (a_calendarioIds) {
    const ids = a_calendarioIds.join(",");
    const url = process.env.VUE_APP_API_BASE_URL +  '/api/v2/messaggio.php?count_commenti&calendario_ids='+ids;
    return _api(url, "GET");
}
const makeKeyMisurazione = function (misurazione) {
    const key = misurazione.parametro+'-'+misurazione.data;
    return key;
}

const loadMisurazioni = async function (atletaId,da,a) {
    const misurazioni = await _misurazioni.loadMisurazioni(atletaId,da,a);
    return misurazioni;
}


const inLimits = function (state, start, end) {
    if (!(state.limits && state.limits.start && state.limits.end)) {
        return false;
    }
    let inLimitStart = _cal.inLimits(state.limits.start, state.limits.end, start);
    let inLimitEnd = _cal.inLimits(state.limits.start, state.limits.end, end);
    const inLimits =  inLimitStart && inLimitEnd;
    return inLimits;
}


const makeEvent = function (el, type) {
  //...
  let mapType = {
    'allenamenti': 'allenamento',
    'gare': 'gara',
    'tests': 'test',
    'impegni': 'impegno',
    'attivita': 'attivita',
    'pesi': 'peso',
  };
  let filtered_type = (Object.prototype.hasOwnProperty.call(mapType, type)) ? mapType[type] : type;
  let start_date = _cal.utcDate(el.data);
  let end_date = (el.data_fine) ? _cal.utcDate(el.data_fine) : start_date;
  let event = {
    start: start_date,
    end: end_date,
    start_ymd: el.data,
    end_ymd: el.data_fine,
    name: el.titolo ? el.titolo : "senza titolo",
    color: "var(--col-sport-" +  el.sport_id + ")",
    type: filtered_type,
    id: el.id,
    calendario_id: el.calendario_id,
    data: {...el}
  }
  return event;
}
const state = () => ({
    cache: false,
    events: [],
    commenti: [],
    misurazioni: [],
    attivita: [],
    peso: [],
    current: null,
    currentDay: '',
    calendarEvents: [],
    dialog: false,
    eventsStatus: null,
    event: null,
    limits: {
        start: "",
        end: "",
    },
});

// getters
const getters = {

  events: (state) => {
    return state.events;
  },
  commenti: (state) => {
    return state.commenti;
  },
  misurazioni: (state) => {
    return state.misurazioni;
  },
  allenamenti: (state) => {
    return state.events.filter(el=>el.type==="allenamento");
  },
  test: (state) => {
    return state.events.filter(el=>el.type==="test");
  },
  impegni: (state) => {
    return state.events.filter(el=>el.type==="impegno");
  },

  gare: (state) => {
    return state.events.filter(el=>el.type==="gara");
  },
    atletaId: (state, getters, rootState) => {
        return rootState.atleti.atletaId;
    }

};

// actions
const actions = {
    
    /** annulla la cache.
     * In caso contrario, utilizza la cache */
    cacheOff: function ({commit}) {
        commit("cacheOff");
    },
    cacheOn: function ({commit}) {
        commit("cacheOn");
    },
    ready: function ({dispatch}) {
        let start = _cal.date2ymd();
        let end = _cal.date2ymd();
        dispatch('loadCalendar',{start: start,end: end});
    },

    setEvent: function ({commit}, event) {
        commit('setEvent', event);
    },

    setAtletaId: function ({commit, dispatch}, atletaId) {
        commit('setAtletaId', atletaId);
        dispatch('reset');
    },

    setDialog: function ({commit}, dialog) {
        commit('setDialog', dialog);
    },

    setCurrentDay: function ({commit}, day) {
        commit('setCurrentDay', day);
    },


    updatePeso: function ({commit},{id, event}) {
        commit('updatePeso', {id, event});
    },

    removeAttivita: function ({commit},{id}) {
        commit('removeAttivita', {id});
    },
    updateEvent: function ({commit},{id, event}) {
        commit('updateEvent', {id, event});
    },

    addAttivita: function ({commit}, {attivita}) {
        commit("addAttivita", {attivita:attivita});
    },

    addPeso: function ({state, commit}, {peso}) {
        if (!state.peso.find(el=>el.id===peso.id)) {
            commit("addPeso", {peso:peso});
        } else {
            commit('updatePeso',{id: peso.id, event:peso});
        }
    },


    removePeso: function ({commit}, peso_id) {
        commit("removePeso", peso_id);
    },

    loadAttivita: async function({commit, getters}, {start, end}) {
        let params = {
            liberi: 1,
            da: start,
            a: end,
            atleta_id: getters.atletaId,
        };
        const qs = '?' + new URLSearchParams(params).toString()
        return new Promise((resolve, reject)=>{
            const url = process.env.VUE_APP_API_BASE_URL + "/api/v2/attivita.php" + qs;
            _api(url, "GET")
                .then((result)=>{
                    if (result.data) {
                        result.data = result.data.map(el=>Object.assign({},el,{'data': el.dataAllenamento.substr(0,10)}));
                        commit("addAttivita", {attivita:result.data});
                        resolve(result.data);
                    }
                }
                )
                .catch((error)=>{
                    reject(error);
                });

        });
    },





    loadNext: async function ({dispatch,state}) {
        if (!state.limits.end) { return; }
        let limits = _cal.nextWeek(state.limits.end);

        await dispatch("loadEvents", {start: limits.from, end: limits.to});
        dispatch("setLimits", {start: state.limits.start, end: limits.to});
    },
    async loadPrev({dispatch,state}) {
        if (!state.limits.end) { return; }
        let limits = _cal.prevWeek(state.limits.start);

        await dispatch("loadEvents", {start: limits.from, end: limits.to});
        dispatch("setLimits", {start: limits.from, end:state.limits.end});
    },

    deletePeso: function({commit}, id) {
        let url = process.env.VUE_APP_API_BASE_URL+"/api/v2/peso.php?id="+id;
        return new Promise((resolve,reject)=>{
            _api(url, "DELETE")
                .then((result)=>{
                    if (result.success) {
                        commit("deletePeso", id);
                        resolve(result);
                    } else {
                        resolve(null);
                    }
                })
            .catch((error)=>{
                reject(error);
            });
        });
    },


    deleteEvent: function({commit}, id) {
        let url = process.env.VUE_APP_API_BASE_URL+"/api/v2/calendario.php?id="+id;

        return new Promise((resolve,reject)=>{
            _api(url, "DELETE")
                .then((result)=>{
                    if (result.success) {
                        commit("deleteEvent", id);
                        resolve(result);
                    } else {
                        resolve(null);
                    }
                })
                .catch((error)=>{
                    reject(error);
                });
        });
    },
    loadCalendar({dispatch}, {start, end}) {
        let params = {
            start: start,
            end: end,
        }

        return new Promise((resolve, reject)=>{
            dispatch("loadEvents", params)
                .then(
                    result=>{
                        if (result.success) {
                            resolve(result)
                        } else {
                            resolve(false)
                        }
                    },
                    (error) => {
                        reject(error);
                    }
                )
        });
    },

    setLimits: function ({commit}, {start, end}) {
        commit('setLimits', {start: start, end: end});
    },
    resetLimits: function ({commit}) {
        const dateStart = _cal.utcDate();
        const start = _cal.date2ymd(dateStart);
        const end = _cal.addDays(start, 7);

        commit('setLimits', {start: start, end: end});
    },
    resetCalendar: function ({commit}) {
        commit('resetCalendar');
    },
    reset: function ({dispatch}) {
        dispatch("cacheOff");
        dispatch("resetLimits");
        dispatch("resetCalendar");
    },
    addEvent: function ({commit}, {event, type}) {
        commit("addEvent", {event: event, type: type});
    },
    loadEvents: function ({commit, state, dispatch, getters}, {start, end}) {
        dispatch("messaggio/countMessages",null,{root:true});
        if (state.cache && inLimits(state, start, end)) return Promise.resolve({success:false});
        const atleta_id = getters.atletaId;
        if (!atleta_id) return Promise.resolve({success:false});
        dispatch("setLimits", {start: start, end: end});
        dispatch('loadAttivita',{start,end});
        dispatch("loadMisurazioni",{start,end})

        const from = start;
        const to = end; 
        return new Promise((resolve, reject) => {
            let data = {
                calendari: "allenamento,gara,test,risultato,impegno",
                da: from,
                a: to,
                atleta_id: atleta_id,
            }
            const params = URLparams(data);
            const url = process.env.VUE_APP_API_BASE_URL + "/api/v2/calendario.php?" + params;

            _api(url, "GET")
                .then(async (result)=>{
                    commit('cacheOn');
                    commit("addEvents", {events: result.data})
                    dispatch("updateCountCommenti")
                    resolve(result);
                })
                .catch((error)=>{
                    reject(error);
                });
        });
    },
    updateCountCommenti: async function ({state, commit}) {
        let ids = state.events.filter(el=>eventiConCommenti.indexOf(el.type)!==-1).map(el=>el.id);
        if (!ids) {
            return;
        }
        const commenti = await countCommenti(ids);
        if (!(commenti && commenti.success)) {
            return;
        }
        const chats = commenti.data;
        if (!(chats && chats.length)) {
            return;
        }
        commit("updateCountCommenti", {chats: chats})
    },

    loadMisurazioni: async function ({commit, getters}, {start, end}) {
        const atleta_id = getters.atletaId;
        const misurazioni = await loadMisurazioni(atleta_id, start, end);
        commit("updateMisurazioni", {misurazioni: misurazioni, start: start, end: end});
    },

    loadCalendarEvents: function ({commit, getters}, {start, end}) {
        const atleta_id = getters.atletaId;
        if (!atleta_id) return Promise.resolve({success:false});
        const from = start;
        const to = end;
        return new Promise((resolve, reject) => {
            let data = {
                calendari: "allenamento,gara,test,risultato,impegno",
                da: from,
                a: to,
                atleta_id: atleta_id,
            }
            const params = URLparams(data);
            const url = process.env.VUE_APP_API_BASE_URL + "/api/v2/calendario.php?" + params;

            _api(url, "GET")
                .then((result)=>{
                    commit("setCalendarEvents", {events: result.data})
                    resolve(result);
                })
                .catch((error)=>{
                    reject(error);
                });
        });
    },
    loadCommenti: function ({commit, state}) {
        let ids = state.events.map(el=>el.calendario_id).join(",");
        return new Promise((resolve, reject) => {
            const url = process.env.VUE_APP_API_BASE_URL + "/api/v2/messaggio.php?calendario_ids=" + ids;

            _api(url, "GET")
                .then((result)=>{
                    commit("setCommenti", {commenti: result.data})
                    resolve(result);
                })
                .catch((error)=>{
                    reject(error);
                });
        });
    }
};

// mutations
const mutations = {
    cacheOff (state) {
        state.cache = false;
    },
    cacheOn (state) {
        state.cache = true;
    },
    setEvent (state, event) {
        state.current = event.data;
        state.event = event;
    },
    setCommenti (state, {commenti}) {
        state.commenti = commenti;
    },

    setAtletaId (state, atletaId) {
         void(atletaId);
    },

    setDialog (state, dialog) {
        state.dialog = dialog;
    },

    setCurrentDay (state, day) {
        state.currentDay = day;
    },

    setLimits (state, { start, end }) {
        let finalStart;
        let finalEnd;
        if (!state.cache || (!state.limits.start || (start < state.limits.start))) {
            finalStart = start;
        } else {
            finalStart =  state.limits.start;
        }

        if (!state.cache || (!state.limits.end || (end > state.limits.end))) {
            finalEnd = end;
        } else {
            finalEnd =  state.limits.end;
        }
        state.limits = Object.assign({
            start: finalStart,
            end: finalEnd,
        });
    },

    setEvents (state, { events }) {
        state.events = events;
    },

    setAttivita (state, { attivita }) {
        state.attivita = attivita;
    },

    setPeso (state, { peso }) {
        state.peso = peso;
    },

    setAutovalutazione (state, { autovalutazione }) {
        state.autovalutazione = autovalutazione;
    },

    addAttivita (state, { attivita }) {
        attivita.forEach(at=>{
            if (!state.attivita.find(el=>el.id===at.id)) {

                let event_attivita = makeEvent(at, 'attivita');
                state.attivita.push(event_attivita);

            }
        })
    },
    removeAttivita (state, {id}) {
        let index = state.attivita.findIndex(at=>at.id==id);
        if (index !== -1) {
            state.attivita.splice(index,1);
        }
    },

    addPeso (state, { peso }) {
        if (!state.peso.find(el=>el.id===peso.id)) {
            state.peso.push(peso);
        }
    },

    addAutovalutazione (state, { autovalutazione }) {
        autovalutazione.forEach(at=>{
            if (!state.autovalutazione.find(el=>el.id===at.id)) {
                state.autovalutazione.push(at);
            }
        })
    },

    removePeso (state, peso_id) {
        let index = state.peso.findIndex(at=>at.id===peso_id);
        if (index !== -1) {
            state.peso.splice(index,1);
        }
    },

    removeAutovalutazione (state, autovalutazione_id) {
        let index = state.autovalutazione.findIndex(at=>at.id===autovalutazione_id);
        if (index !== -1) {
            state.autovalutazione.splice(index,1);
        }
    },

    deleteEvent(state, id) {
        let indexEvent = state.events.findIndex((el)=>{return el.id == id});
        if (indexEvent !== -1) {
            state.events.splice(indexEvent,1);
        }
    },

    deletePeso(state, id) {
        let index = state.peso.findIndex((el)=>{return el.id === id});
        if (index !== -1) {
            state.peso.splice(index,1);
        }
    },

    deleteAutovalutazione(state, id) {
        let index = state.autovalutazione.findIndex((el)=>{return el.id === id});
        if (index !== -1) {
            state.autovalutazione.splice(index,1);
        }
    },

    resetCalendar: function (state) {
        state.events = [];
        state.attivita = [];
        state.groups = [];
        state.misurazioni = [];
        state.commenti = [];
    },

    setCalendarEvents (state, { events }) {
        state.calendarEvents = [];
        for (let k in events) {
            if (this.autogroups) {
                if (this.groups.indexOf(k)===-1) {
                    this.groups.push(k);
                }
            }
            events[k] && events[k].forEach(
                el=>{
                    if (state.calendarEvents.find(ev=>ev.calendario_id===el.calendario_id)) {
                        return;
                    }

                    //
                    // let mapType = {
                    //   'allenamenti': 'allenamento',
                    //   'gare': 'gara',
                    //   'tests': 'test',
                    // };
                    // // let type = (Object.prototype.hasOwnProperty.call(mapType, k)) ? mapType[k] : k;
                    // //W1 TODO: differenziare GARE
                    let attribute;

                    if (Object.prototype.hasOwnProperty.call(el, 'impegno_tipo_id')) {
                        attribute = state.calendarEvents.find(ev=>ev.bar&&(ev.bar.id===el.id));

                        if (!attribute) {

                            attribute = {
                                bar: {
                                    id: el.id,
                                    class: 'impegno'
                                },
                                dates:[]
                            }
                            state.calendarEvents.push(attribute);
                            let current = new Date(el.data);
                            if (!el.data_fine) {
                                attribute.dates.push(current);
                            } else {

                                let last = new Date(el.data_fine);
                                while(current <= last) {
                                    let data = new Date(current);
                                    attribute.dates.push(data);
                                    current = new Date(current.setDate(current.getDate() + 1));
                                }
                            }
                        }

                    } else {

                        attribute = state.calendarEvents.find(ev=>el.dot&&(el.dot.class==='sport-'+ev.sport_id));
                        if (!attribute) {
                            attribute = {
                                dot: {
                                    class: 'sport-'+el.sport_id
                                },
                                dates: []
                            };
                            state.calendarEvents.push(attribute);

                        }
                        attribute.dates.push(new Date(el.data));
                    }

                }
            )
        }
    },
    addEvents (state, { events }) {
        for (let k in events) {
            if (this.autogroups) {
                if (this.groups.indexOf(k)===-1) {
                    this.groups.push(k);
                }
            }
            events[k] && events[k].forEach(
                el=>{
                    let event = makeEvent(el, k);
                    const index = state.events.findIndex(ev=>ev.calendario_id===el.calendario_id);
                    if (index !== -1) {
                        state.events.splice(index,1,event);
                    } else {
                        state.events.push(event);
                    }
                }
            )
        }
    },
    /*
calendario_id: 
color: 
data:
end: 
end_ymd: 
id: 
name: 
start: 
start_ymd: 
type: 
*/
    updatePeso: function (state, {id, event}) {
        const index = state.peso.findIndex((el)=>{
            return +el.id===+id;
        });
        if (index===-1) {
            return;
        }
        let oldEvent = state.peso[index];
        let updated = Object.assign({}, oldEvent, event);
        state.peso.splice(index,1,updated);
    },

    updateAutovalutazione: function (state, {id, event}) {
        const index = state.autovalutazione.findIndex((el)=>{
            return +el.id===+id;
        });
        if (index===-1) {
            return;
        }
        let oldEvent = state.autovalutazione[index];
        let updated = Object.assign({}, oldEvent, event);
        state.autovalutazione.splice(index,1,updated);
    },

    updateEvent: function (state, {id, event}) {
        const index = state.events.findIndex((el)=>{
            return +el.id===+id;
        });
        if (index===-1) {
            return;
        }
        let oldEvent = state.events[index];
        let newEvent = {
            ...oldEvent.data,
            ...event.data
        };

        let updated = makeEvent(newEvent, oldEvent.type);
        state.events.splice(index,1,updated);
    },

    addEvent: function (state, { event, type }) {
        let newEvent = makeEvent(event, type);
        state.events.push(newEvent);

    },

    /** Aggiorna le misurazioni in un range di date.
    * Prima toglie da state tutte le misurazioni nel range.
    * Aggiunge le nuove misurazioni
    */
    updateMisurazioni: function (state, {misurazioni, start, end}) {
        if (!(misurazioni && misurazioni.length)) return;
        
        let misurazioniNotInRange = _misurazioni.misurazioniNotInRange(state.misurazioni, start, end);
        state.misurazioni = [...misurazioniNotInRange];
        
        misurazioni.forEach((misurazione)=>{
            const key = makeKeyMisurazione(misurazione);
            misurazione.key = key;
            state.misurazioni.push(misurazione);
        });
    },

    updateCountCommenti: function (state, {chats}) {
        const calendarioIds = chats.map(el=>el.calendario_id);
        const events = state.events.map(el=>{
            if (eventiConCommenti.indexOf(el.type)==-1) return el;
            let index = calendarioIds.indexOf(el.calendario_id);
            if (index === -1) return el;
            const chat = chats[index];
            const newEl = {
                ...el,
                data: {
                    ...el.data,
                    chat_count_totale: chat.count_totale,
                    chat_count_nuovi: chat.count_nuovi,
                }
            };
            return newEl;
        });
        state.events = events;
    },
}

  export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
  };
