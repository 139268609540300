import Cookies from 'js-cookie'
const storage = {
    get: function (key) {
        const value = localStorage.getItem(key);
        let result;
        try {
            result = JSON.parse(value);
        } catch {
            result = value;
            
        }
        return result;
    },
    set: function (key, value) {
        const json = JSON.stringify(value);
        return localStorage.setItem(key, json);
    },
    remove: function (key) {
        return localStorage.removeItem(key);
    },
    askAuthorization: async function () {
        return new Promise((resolve)=>{
            if (navigator.storage && navigator.storage.persist) {
                navigator.storage.persist().then((persistent) => {
                    resolve(persistent);
                });
            } else {
                resolve(false);
            }
        });
    },
    getAuthToken: function () {
        const auth_token = Cookies.get('CP_AUTH');
        storage.set("auth_token", auth_token);
        return auth_token;

    },
    setAuthToken: function (token) {
        const options = {
            expires: 30,
            sameSite: 'strict',
            secure: true,
            path: '/',
        };
        Cookies.set('CP_AUTH', token, options);
        storage.set("auth_token", token);
    },
    deleteAuthToken: function () {
        const options = {
            path: '/',
        };
        Cookies.remove('CP_AUTH', options);
        storage.remove('auth_token');
    },

    getAtletaId: function () {
        return storage.get("atletaId");
    },

    setAtletaId: function (id) {
        return storage.set("atletaId", id);
    },
};

export default storage;
