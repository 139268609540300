import api from "@mixins/api.js";
import _messaggi from "@mixins/messaggi.js";

const makeChatsList = function (messaggi, chats) {

    let commenti = messaggi.map((messaggio)=>{
        let chat = chats.find(ch=>ch.id == messaggio.chat_id);
        let utente = chat.utenti.find(el=>el.id==messaggio.utente_id);
        const testo = (messaggio.testo) ? _messaggi.makeExcerpt(messaggio.testo) : null;
        return {
            utente: utente,
            chat: {
                chat: chat,
                messaggio: messaggio,
                testo: testo,
            }
        }
    });

    return commenti;
}

const countNewMessages = async function() {
    return new Promise((resolve)=>{
        let api_base_url = process.env.VUE_APP_API_BASE_URL;
        let url = api_base_url+"/api/v2/messaggio.php?count";
        api(url, 'GET', null)
            .then((result)=>{
                if (result.success) {
                    resolve(result.data.count);
                } else {
                    resolve(0);
                }
            });
    });
};

const countNewCommenti = async function() {
    return new Promise((resolve)=>{
        let api_base_url = process.env.VUE_APP_API_BASE_URL;
        let url = api_base_url+"/api/v2/messaggio.php?count_commenti";
        api(url, 'GET', null)
            .then((result)=>{
                if (result.success) {
                    resolve(result.data);
                } else {
                    resolve(0);
                }
            });
    });
};

// Poll interval per count messaggi

const state = () => ({
    dialog: false,
    messages: 0,
    commenti: [],
    countNewCommenti: 0,
    timeout: {
        commenti: 0,
        messages: 0,
    }
});

// getters
const getters = {
  messaggi: (state) => { return state.messages; },
  commenti: (state) => { return state.commenti; },
  countNewCommenti: (state) => { return state.countNewCommenti; },
};

// actions: (dispatch) sono il Controller 
const actions = {
    setDialog: function ({commit}, dialog) {
        commit('setDialog', dialog);
    },

    countMessages: async function (context) {
        let messages = await countNewMessages();
        context.commit('setMessages', messages);
    },

    countCommenti: async function (context) {
        let count = await countNewCommenti();
        context.commit('setCountNewCommenti', count);
        context.dispatch("calendario/updateCountCommenti",null,{root:true});
    },

    initCountMessages: async function (context) {
        if (context.state.timeout.messages) return;
        context.dispatch('countMessages', context);
    },

    initCountCommenti: async function (context) {
        if (context.state.timeout.commenti) return;
        context.dispatch('countCommenti', context);
    },

    loadCommenti: async function (context, {limit, page}) {
        const params = {
            limit: limit,
            page: page,
        };
        context.dispatch("countCommenti", context);
        const result = await _messaggi.loadChatsCalendario(params);
        if (result.success) {
            let messaggi = result.data.messaggi;
            if (!messaggi && messaggi.length) return;
            const commenti = makeChatsList(messaggi, result.data.chats);
            context.commit("addCommenti", commenti);
        }
    },
    resetCommenti: async function ({commit}) {
        commit("resetCommenti");
    },

};

// mutations: (commit) cambiano lo state (Model)
const mutations = {
    setDialog (state, dialog) {
        state.dialog = dialog;
    },

    setTimeout (state, {timeout, type}) {
        state.timeout[type] = timeout;
    },
    setMessages (state, messages) {
        state.messages = messages;
    },
    setCommenti (state, commenti) {
        state.commenti = commenti;
    },
    resetCommenti (state) {
        state.commenti = [];
    },

    addCommenti (state, commenti) {
        commenti.forEach((commento)=>{
            let ids = state.commenti.map(el=>el.chat.messaggio.id);
            if (ids.indexOf(commento.chat.messaggio.id) == -1) {
                state.commenti.push(commento);
            }
        });
    },
    setCountNewCommenti (state, countNewCommenti) {
        state.countNewCommenti = countNewCommenti;
    },

}

  export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
  };
