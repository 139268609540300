import Vue from "vue";
import Vuex from "vuex";

// register VueFusionCharts component


import App from "./App.vue";
import VueForceNextTick from "vue-force-next-tick";
import vuetify from "./plugins/vuetify";
import router from "./router";
import "./registerServiceWorker";

import store from "./store";

import VCalendar from 'v-calendar';
import _storage from "@mixins/storage";

// Use v-calendar & v-date-picker components
Vue.use(VCalendar, {
  componentPrefix: 'vc',  // Use <vc-calendar /> instead of <v-calendar />
});




Vue.use(VueForceNextTick);
Vue.use(Vuex);
Vue.config.productionTip = false;
Vue.config.ignoredElements = [/^ion-/];
_storage.askAuthorization()
.then(()=>{
new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
});
