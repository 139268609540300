const state = () => ({
    headerHeight: 0,
});

// getters
const getters = {
    headerHeight: (state) => (state.headerHeight),
};


// actions: (dispatch) sono il Controller 
const actions = {

    setHeaderHeight: function ({commit}, height) {
        commit('setHeaderHeight', height);
    },

    setAtleta: async function ({ dispatch,rootState }, atleta) {
        const oldAtleta = rootState.atleti.atleta;
        if (oldAtleta && oldAtleta.id == atleta.id) return;
        dispatch("atleti/setAtleta", atleta, {root: true});
        dispatch("calendario/setAtletaId", atleta.id, {root: true});
        dispatch("gare/setAtletaId", atleta.id, {root: true});
        dispatch("calendario/reset", null, {root: true});
    }

};

// mutations: (commit) cambiano lo state (Model)
const mutations = {

    setHeaderHeight (state, height) {
        state.headerHeight = height
    },
    

}

  export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
  };
