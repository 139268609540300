import { environment } from './env.js';
export const env = {
    api_base_url: environment.VUE_APP_API_BASE_URL,
    resources_base_url: environment.VUE_APP_RESOURCES_BASE_URL,
    base_url: environment.BASE_URL,
    flag_env: environment.VUE_APP_FLAG_ENV,
    paypal_client_id: environment.VUE_APP_PAYPAL_CLIENT_ID,
}

export default {
  data: function () {
    return env;
  },
};
