import api from './api.js';
import { env } from './environment.js'
const endpoint = env.api_base_url+"/api/v2/messaggio.php";
const chatTypes = {
"calendario": {
    "endpoint": "chat_calendario",
    "key": "calendario_id"
},
    "one_on_one": {
        "endpoint": "chat_one_on_one",
        "key": "utente_id",
    }
};
const htmlDecode = function (input) {
    var doc = new DOMParser().parseFromString(input, "text/html");
    return doc.documentElement.textContent;
}

const messaggi = {
    makeExcerpt: function (text) {
        if (!(text && text.length)) return "";
        let excerpt = text.replace(/<\/?[^>]+(>|$)/g, "");
        excerpt = htmlDecode(excerpt);
        return excerpt;
    },
    postMessageUpdateFeeds: function () {
        let message = {
            action: 'updateFeeds',
            data: null,
        };
        window && window.postMessage(message);
        window.opener && window.opener.postMessage(message);
    },
    setLetti: async function (a_ids = []) {
        let ids = a_ids.join(",");
        let url = endpoint+"?letto&ids="+ids;
        let result = await api(url, "PUT", null);
        if (!result.success) return false;
        return result.data.updated;
    },
    send: async function (chatId, text) {
        let url = endpoint+"?chat_id="+chatId
        let data = {
            "testo": text,
        };
        let result = await api(url, "POST", data);
        return result;
    },
    loadMessages: async function (chatId) {
        let url = endpoint+"?chat_id="+chatId;
        const result = await api(url, 'GET', null);
        return result;
    },
    loadChatsOneOnOne: async function () {
        let url = endpoint+"?chats_one_on_one";
        const result = await api(url, 'GET', null);
        return result;
    },
    loadChatsCalendario: async function ({page,limit}) {
        let url = endpoint+`?commenti&page=${page}&limit=${limit}`;
        const result = await api(url, 'GET', null);
        return result;
    },
    fails: function () {
        return Promise.resolve({success: false});
    },
    findChatType: function (nome) {
        if (!chatTypes[nome]) return null;
        return chatTypes[nome];
    },
    addChat: async function (type, id) {
        const chatType = messaggi.findChatType(type);
        if (!chatType) {
            return messaggi.fails();
        }
        let url = endpoint+"?"+chatType.endpoint;
        let data = {
            [chatType.key]: id,
        };
        let result = await api(url, "POST", data);
        if (!result.success) {
            return false;
        }
        return result.data;
    },
}
export default messaggi;
