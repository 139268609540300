import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
Vue.use(Vuetify);

export default new Vuetify({
  options: {
    customProperties: true
  },
  icons: {
  iconfont: 'mdi', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
},
  theme: {
    themes: {
      light: {
        primary: '#4cb24c',
        secondary: '#E78C0C',
        accent: '#0b95ee',
        error: '#cc0000',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107'

      },
    }
  }
});
