import _api from './api.js';
import { env } from './environment.js'
const endpoint = env.api_base_url+"/api/v2/utente.php";
const utente = {
    isCoach: async function isCoach () {
        let url = endpoint+"?is_coach";
        let result = await _api(url, "GET", null);
        if (result.success && result.data) {
            return true;
        }
        return false;
    },

    getOption: async function getOption (option) {
        let url = endpoint+"?opzione="+option;
        let result = await _api(url, "GET", null);
        if (result.success && result.data) {
            return result.data;
        }
        return false;
    },

    setOption: async function setOption (key, value) {
        const payload = {
            [key]: value,
        };
        let url = endpoint+"?opzione";
        let result = await _api(url, "POST", payload);
        if (result.data && result.data.success) {
            return result.data;
        }
        return false;
    },

}
export default utente;
