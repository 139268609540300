import _api from "@mixins/api.js";
import sport_gara from "@cached/sport_gara.json"
import sport_specialita from "@cached/sport_specialita.json"

const findSpecialita  = function (sport_specialita, sport_id) {
    let filteredSportSpecialita = sport_specialita.filter((el)=>{
        return el.sport_id === sport_id;
    });
    return filteredSportSpecialita;
}

const makeSports = function () {
    let sports = sport_gara;
    let tutti = {
        id: 0,
        descrizione: 'Tutti',
        posizione: 0,
    };
    sports.push(tutti);
    sports.sort((a,b)=>((+a.posizione)-(+b.posizione)));
    return sports;
}
const makeYears = function () {
    let first = 2019; //anno di inaugurazione di CoachPeaking
    let currentYear = new Date().getFullYear();
    let items = currentYear-first+1;
    let anni = [...Array(items)].map((n,i)=>(first+i));
    anni.push("Tutti gli anni");
    anni.push("Prossime gare");
    anni.reverse();
    return anni.map((el, id)=>{
        return {
            value: id,
            label: el,
        }
    });
}

const state = () => ({
    atletaId: 0,
    gare: [],
    dialog: false,

    sport_specialita: sport_specialita,
    sortAsc: true,
    years: makeYears(),
    filterAnno: null,
    sportId: 0,
    sportSpecialitaId: 0,
    sports: makeSports(),
    specialita: [],
    limits: {
        params: null,
    },
});

// getters
const getters = {

  gare: (state) => { return state.gare; },
  cerca: (state) => { 
      return {
          sport_id: state.sportId,
              sport_specialita_id: state.sportSpecialitaId
      };
  },
  sports: (state) => { return state.sports; },
  sport_specialita: (state) => { return state.sport_specialita; },
  years: (state) => { return state.years; },
  filterAnno: (state) => { return state.filterAnno; },
  sortAsc: (state) => { return state.sortAsc; },
  specialita: (state) => { return state.specialita; },
};

// actions
const actions = {

    setAtletaId: function ({commit}, atletaId) {
        commit('setAtletaId', atletaId);
        // commit('update');
    },

  setDialog: function ({commit}, dialog) {
    commit('setDialog', dialog);
},
    updateSpecialita: function ({commit}) {
        commit("updateSpecialita");
    },
     setSortAsc: function ({commit}, {sortAsc}) {
        commit("setSortAsc", {sortAsc: sortAsc});
    },
    switchSortOrder: function ({commit}) {
        commit("switchSortOrder");
    },
    deleteGara: function ({commit}, id) {
        commit("deleteGara", id);
    },
    setGara: function ({commit}, {id, gara}) {
        commit("setGara", {id:id, gara:gara});
    },
    setSport: function ({commit}, {id}) {
        commit("setSport", {id: id});
    },
    setSportSpecialita: function ({commit}, {id}) {
        commit("setSportSpecialita", {id: id});
    },
    setFilterAnno: function ({commit}, {value}) {
        commit("setFilterAnno", {value: value});
    },

    load: function ({commit, state}, {params}) {
        let atletaId = state.atletaId;
        if (!atletaId) return Promise.resolve({
            success:false,
            data: null,
        });

        params += `&atleta_id=${state.atletaId}`;

        return new Promise((resolve, reject) => {
            const url = process.env.VUE_APP_API_BASE_URL + "/api/v2/calendario.php?" + params;

            _api(url, "GET")
                .then((result)=>{
                    let gare;
                    if (result.data && result.data.gara) {
                        gare = result.data.gara;
                    } else {
                        gare = [];
                    }
                    commit("setGare", {gare: gare})
                    resolve(result);
                })
                .catch((error)=>{
                    reject(error);
                });
        }
    );
  }
};

// mutations
const mutations = {
  setDialog (state, dialog) {
    state.dialog = dialog;
},

    setAtletaId (state, atletaId) {
        state.atletaId = atletaId;
    },

    setSortAsc: function (state, {sortAsc}) {
        state.sortAsc = sortAsc;
    },

    switchSortOrder: function (state) {
        state.sortAsc = !state.sortAsc;
    },

    updateSpecialita: async function (state) {
        state.sportSpecialitaId = 0;
        let specialita = findSpecialita(sport_specialita, state.sportId);
        if (specialita) {
            state.specialita = [{id:0,descrizione:'Tutti'}].concat( specialita.sort((a,b)=>(a.posizione>b.posizione)?1:-1));
        } else {
            state.specialita = []
        }
    },

    deleteGara: function (state, id) {
        if (!(state.gare && state.gare.length)) return;
        let index = state.gare.findIndex(el=>el&&el.id === id);
        if (index === -1) return;
        let gare = [...state.gare];
        gare.splice(index,1,null);
        state.gare = [...gare];
    },
    setGara: function (state, {id, gara}) {
        if (!(state.gare && state.gare.length)) return;
        let index = state.gare.findIndex(el=>(el&&el.id === id));
        if (index === -1) return;
        let gare = [...state.gare];
       gare.splice(index,1,{...gara});
        state.gare = [...gare];
    },

    setSport: function (state, {id}) {
        state.sportId = id;
    },

    setSportSpecialita: function (state, {id}) {
        state.sportSpecialitaId = id;
    },

    setFilterAnno (state, { value }) {
        state.filterAnno = value;
    },
    setGare (state, { gare }) {
        state.gare = gare;
    },
}

  export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
  };
