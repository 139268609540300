import api from "@mixins/api.js";
import {env} from "@mixins/environment.js"
import _utente from "@mixins/utente.js";
import _storage from "@mixins/storage.js"

const getAuthToken =  _storage.getAuthToken;
const setAuthToken =  _storage.setAuthToken;

/**
 * Effettua il login usando l'auth_token salvato in localStorage.
 * @return Object utente
 */
const authTokenLogin = async function () {
    const url = env.api_base_url+'/api/v2/utente.php?token_login=1';
    const result = await api(url, 'POST');
    if (result.data) {
        return result.data;
    } else {
        return false;
    }
}

/**
 * Effettua il login usando l'auth_token salvato in localStorage.
 * @return String auth_token
 */
const login = async function (auth) {
    const url = env.api_base_url+'/api/v2/utente.php?authorize=1';
    const token = `${auth.username}:${auth.password}`;
    const encodedToken = Buffer.from(token).toString('base64');
    const headers = { 'Authorization': 'Basic '+ encodedToken };
    const result = await api(url, 'PUT', null, headers);
    if (result.data) {
        setAuthToken(result.data);
        const auth_token = result.data;    
        return auth_token;
    } else {
        return false;
    }
}


const logout = async function () {
    const url = env.api_base_url+'/api/v2/utente.php?logout';
    const result = await api(url, 'DELETE');
    if (result.success) {
        return true;
    } else {
        return false;
    }
}
const state = () => ({
    options: {},
    utente: null,
    auth_token: null,
});

// getters
const getters = {
    utente: (state) => (state.utente),
    options: (state) => (state.options),
};

// actions: (dispatch) sono il Controller 
const actions = {
    getOption: async function ({state}, key) {
        if (!(key in state.options)) {
        const value = await _utente.getOption(key);
            state.options[key] = value;
        }
        return state.options[key];
    },

    setOption: function ({commit}, { key, value }) {
        commit('setOption',{key:key, value:value});
    },


    setUtente: function ({commit}, utente) {
        commit('setUtente', utente);
    },

    login: async function ({commit,dispatch}, auth = null) {
        let auth_token;
        if (auth) {
            auth_token = await login(auth);
        } else {
            auth_token = getAuthToken();
        }
        commit('setAuthToken', auth_token);

        const utente = await authTokenLogin();
        if (utente) {
            commit('setUtente', utente);
            if (utente.is_atleta) {
                dispatch('atleti/loadAtleta', utente.utente_id, {root:true});
            } else {
                let atleta_id = _storage.get('atletaId');
                if (atleta_id) {
                    dispatch('atleti/loadAtleta', atleta_id, {root:true});
                }
            }
            return utente;
        } else {

            return dispatch('logout');
        }

    },

    logout: async function ({commit}) {
        const isLoggedOut = await logout();
        if (!isLoggedOut) return false;
        _storage.deleteAuthToken();
        commit('setAuthToken', null);
        commit('setUtente',null);
        return true;

    },

};

// mutations: (commit) cambiano lo state (Model)
const mutations = {

    setUtente (state, utente) {
        state.utente = utente;
    },
    setAuthToken: function (state, auth_token) {
        state.auth_token = auth_token;
    },
    setOption: async function (state, {key, value}) {
        state.options[key] = value;
        _utente.setOption(key, value);
    },

}

  export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
  };
