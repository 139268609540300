import api from "@mixins/api.js";
import Cookies from 'js-cookie'

const getAlertAcquisto = async function() {
    return new Promise((resolve)=>{
        let api_base_url = process.env.VUE_APP_API_BASE_URL;
        let url = api_base_url+"/api/v2/ecommerce.php?can_show_alert_acquisto";
        api(url, 'GET', null)
            .then((result)=>{
                if (result.success) {
                    resolve(result.data);
                } else {
                    resolve(false);
                }
            });
    });
};

const checkTransazioni = async function() {
    const cp_c_key = Cookies.get('CP_C_KEY');
    return new Promise((resolve)=>{
        let api_base_url = process.env.VUE_APP_API_BASE_URL;
        let url = api_base_url+"/api/v2/utente.php?check_transazioni&cp_c_key="+cp_c_key;
        api(url, 'GET', null)
            .then((result)=>{
                if (result.success) {
                    resolve(result.data);
                } else {
                    resolve(false);
                }
            });
    });
};

const resetAlertAcquisto = async function() {
    return new Promise((resolve)=>{
        let api_base_url = process.env.VUE_APP_API_BASE_URL;
        let url = api_base_url+"/api/v2/ecommerce.php?set_alert_acquisto";
        api(url, 'POST', null)
            .then((result)=>{
                if (result.success) {
                    resolve(result.data);
                } else {
                    resolve(false);
                }
            });
    });
};

const countNewPurchases = async function() {
    return new Promise((resolve)=>{
        let api_base_url = process.env.VUE_APP_API_BASE_URL;
        let url = api_base_url+"/api/v2/ecommerce.php?count_acquisti";
        api(url, 'GET', null)
            .then((result)=>{
                if (result.success) {
                    resolve(result.data);
                } else {
                    resolve(0);
                }
            });
    });
};

// Poll interval per count acquisti

const state = () => ({
    dialog: false,
    alertAcquistiSospesi: false,
    purchases: 0,
    timeout: null,
});

// getters
const getters = {
  acquisti: (state) => { return state.purchases; },
  alertAcquistiSospesi: (state) => { return state.alertAcquistiSospesi; },

};

// actions: (dispatch) sono il Controller 
const actions = {

    setDialog: function ({commit}, dialog) {
        commit('setDialog', dialog);
    },

    setAlertAcquistiSospesi: function ({commit}, status) {
        commit('setAlertAcquistiSospesi', status);
    },

    checkTransazioni: function () {
        checkTransazioni();
    },

    resetAlertAcquistiSospesi: function ({commit}) {
        resetAlertAcquisto();
        commit('setAlertAcquistiSospesi', false);
    },

    loopCountPurchases: async function (context) {
        countNewPurchases().then((purchases)=>{
            context.commit('setPurchases', purchases);
        });
        getAlertAcquisto().then((alert)=>{
            if (alert) {
                context.dispatch('setAlertAcquistiSospesi',true);
            }
        });
        const timeout = setTimeout(
            ()=>{
                context.dispatch('loopCountPurchases', context);
            }, 30000);
        context.commit('setTimeout', timeout);
    },

    initCountPurchases: async function (context) {
        context.dispatch('checkTransazioni');
        if (context.state.interval) return;
        context.dispatch('loopCountPurchases', context);
    }

};

// mutations: (commit) cambiano lo state (Model)
const mutations = {

    setDialog (state, dialog) {
        state.dialog = dialog;
    },

    setAlertAcquistiSospesi (state, status) {
        state.alertAcquistiSospesi = status;
    },

    setTimeout (state, timeout) {
        state.timeout = timeout;
    },

    setPurchases (state, purchases) {
        state.purchases = purchases;
    },

}

  export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
  };
