import _newsfeed from "@mixins/newsfeed.js";
import _calendario from "@src/js/vue/mixins/calendario.js";


// Poll interval per count messaggi

const state = () => ({
    from: '',
    to: '',
    inc: 2,
    dialog: false,
    newsFeeds: [],
});

// getters
const getters = {
};

// actions: (dispatch) sono il Controller 
const actions = {
    resetLimits: function ({dispatch, state}) {
        const to = _calendario.date2ymd();
        const from = _calendario.addDays(to, -1*state.inc + 1);
        const limits = {
            from: from,
            to: to,
        };
        dispatch("setLimits",limits);
    },
    setDialog: function ({commit}, dialog) {
        commit('setDialog', dialog);
    },
    setLimits: function ({commit}, { from, to }) {
        const limits = {
            from: from,
            to: to,
        };
        commit('setLimits', limits);
    },

    resetNewsFeeds: async function ({commit, dispatch}) {
        dispatch("resetLimits");
        commit("resetNewsFeeds");
    },

    loadNewsFeeds: async function (context) {
        const from = context.state.from;
        const to = context.state.to;
        const result = await _newsfeed.loadNewsFeeds(from, to);
        if (result.success) {
            const newsFeeds = result.data;
            context.commit("addNewsFeeds", newsFeeds);
        }
    },

};

// mutations: (commit) cambiano lo state (Model)
const mutations = {
    setDialog (state, dialog) {
        state.dialog = dialog;
    },

    setNewsFeeds (state, newsFeeds) {
        state.newsFeeds = newsFeeds;
    },
    resetNewsFeeds (state) {
        state.newsFeeds = [];
    },
    addNewsFeeds (state, newsFeeds) {
        newsFeeds.forEach((el)=>{
            let index = state.newsFeeds.findIndex((f)=>(f.id === el.id));
            if (index == -1) {
                state.newsFeeds.push(el);
            } else {
                state.newsFeeds.splice(index, 1, el);
            }
        });

        state.newsFeeds.sort(_newsfeed.sort);
    },
    setLimits: function (state, { from, to }) {
        state.from = from;
        state.to = to;
    },

}

  export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
  };
